@font-face {
font-family: 'Lato';
src: url(/_next/static/media/674fc4748ed43d04-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'Lato';
src: url(/_next/static/media/32b68f916c8915ca-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: 'Lato';
src: url(/_next/static/media/4044014bd1beb414-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: 'Lato';
src: url(/_next/static/media/fa8e0d7af26fdae0-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 900;
font-style: italic;
}

@font-face {
font-family: 'Lato';
src: url(/_next/static/media/e6c0f280476708cb-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 900;
font-style: normal;
}@font-face {font-family: 'Lato Fallback';src: local("Arial");ascent-override: 100.88%;descent-override: 21.77%;line-gap-override: 0.00%;size-adjust: 97.84%
}.__className_1defa7 {font-family: 'Lato', 'Lato Fallback', sans-serif
}.__variable_1defa7 {--Lato: 'Lato', 'Lato Fallback', sans-serif
}

@font-face {
font-family: 'Montserrat';
src: url(/_next/static/media/e745e499933487f7-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 900;
font-style: normal;
}@font-face {font-family: 'Montserrat Fallback';src: local("Arial");ascent-override: 80.59%;descent-override: 20.90%;line-gap-override: 0.00%;size-adjust: 120.11%
}.__className_7f3304 {font-family: 'Montserrat', 'Montserrat Fallback', sans-serif;font-weight: 900;font-style: normal
}.__variable_7f3304 {--Montserrat: 'Montserrat', 'Montserrat Fallback', sans-serif
}

